import React, {useEffect, useState} from 'react';
import Plx from 'react-plx';
import {Constants} from "./Constant";
import {Col, Row} from "react-bootstrap";

function AboutMe() {
    const [aboutMeText, setAboutMeText] = useState('')
    const swipeIn = [
        {
            start: '.About-Me-Image',
            duration: '10%',
            properties: [
                {
                    startValue: -50,
                    endValue: 0,
                    property: 'translateX',
                    unit: "%"
                },
                {
                    startValue: 5,
                    endValue: 0,
                    property: 'blur'
                },
            ],
        }
    ];
    useEffect(() => {
        fetch(Constants.API_BASE_ADDRESS + 'portfolio/aboutme')
            .then(response => response.json())
            .then(data => setAboutMeText(data.text));
    }, [])
    return (

        <section id="aboutme" className="About-Me-Section">
            <header className="Section-Header">About Me</header>
            <Plx parallaxData={swipeIn}>
                <Row className="justify-content-center align-items-center">
                    <Col xs={10} sm={8} lg={4}><img className="About-Me-Image" src="./img/luna.jpg" alt={"profile"}/></Col>
                    <Col xs={10} lg={8}>
                        <article className="About-Me-Text">
                            {aboutMeText}
                        </article>
                    </Col></Row>
            </Plx>
        </section>

    );


}


export default AboutMe;