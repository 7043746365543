import React, {useEffect, useState} from 'react';
import {Constants} from "./Constant";
import AdminPanelMainPageCarouselItem from "./AdminPanelMainPageCarouselItem";
import {Button} from "react-bootstrap";
import {useHistory} from 'react-router-dom'

function AdminPanelMainPageCarousel({handleCallback}) {
    const [data, setData] = useState([])
    const history = useHistory()

    useEffect(() => {
        getSlides()
    }, [])

    const onHandleClick = () => {
        console.log("Callback Received");
        getSlides()
    };

    function getSlides() {
        fetch(Constants.API_BASE_ADDRESS + 'portfolio/slides')
            .then((data) => data.json())
            .then((data) => {
                setData(sortByRating(data))
            })
    }


    function sortByRating(data) {
        return data.sort((a, b) => a.rating > b.rating ? -1 : a.rating < b.rating ? 1 : 0)
    }

    function addCarouselItem() {
        history.push('/carousel/add/')

    }

    return (
        <section>
            <div className='Admin-Portfolio-Items-Wrapper'>
                {data.map((slide, index) => {
                    return <AdminPanelMainPageCarouselItem callback={onHandleClick} item={slide} key={index}/>
                })}
            </div>
            <div className='Admin-Action-Buttons-Right'><Button onClick={addCarouselItem}>Add New</Button></div>
        </section>
    );


}


export default AdminPanelMainPageCarousel;