import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {Constants} from "./Constant";
import SpinnerCustom from "./SpinnerCustom";

function AdminPanelAboutMe({handleCallback}) {
    const [isUpdatingText, setUpdatingText] = useState(false)
    const [aboutMeText, setAboutMeText] = useState('')
    useEffect(() => {
        fetch(Constants.API_BASE_ADDRESS+'portfolio/aboutme')
            .then(response => response.json())
            .then(data => setAboutMeText(data.text));
    }, [])


    function handleSubmit(event) {
        event.preventDefault();
        setUpdatingText(true)
        const data = {
            text: aboutMeText
        }
        fetch(Constants.API_BASE_ADDRESS+'update/aboutme', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('userToken'),
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                setUpdatingText(false)
                //handleCallback();
            })
            .catch((error) => {
                console.error('Error:', error);
                setUpdatingText(false)
            });
    }

    return (
        <section>
            <Container>
                <Row>
                    <Col>
                        {isUpdatingText ? <SpinnerCustom message='updating'/> :
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="aboutMeText">
                                    <Form.Label>About me Text</Form.Label>
                                    <Form.Control as="textarea" rows={5} value={aboutMeText}
                                                  onChange={(e) => setAboutMeText(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Update About Me Text
                                </Button>
                            </Form>
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    );


}


export default AdminPanelAboutMe;