import React from 'react';
import { Spinner} from "react-bootstrap";

function SpinnerCustom({message}) {

    return (
        <>
            <div className="Spinner-Custom">
                <Spinner animation="border" role="status"></Spinner>
                <span className="Spinner-Custom-Message">{message}...</span>
            </div>
        </>
    );
}

export default SpinnerCustom;
