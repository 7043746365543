import React, {useState} from 'react';
import Modal from "./Modal";
import ThreeComponent from "./ThreeComponent";
import {Constants} from "./Constant";
import {Utils} from "./Utils";
import Description from "./DescriptionComponent";
import ExpandableImage from "./ExpandableImage";
import {Col, Container, Row} from "react-bootstrap";
import CarouselGallery from "./CarouselGallery";

function PortfolioModal({modalContent, showModal, onChange}) {

    return (
        <section>
            <Modal show={showModal} setShow={onChange} content={getContentTemplate(modalContent)} key={'modal'}/>
        </section>

    );
}


function getContentTemplate(modalContentObject) {


    return <Container className='Portfolio-Page-Detailed'>

        <Row className="justify-content-lg-start align-items-lg-start">
            <Col className={"text-lg-start"}>
                <h1>{modalContentObject.title}</h1>
            </Col>
        </Row>

        <Row className="justify-content-center align-items-center"><Col xs={10} sm={8} lg={4}><ExpandableImage
            src={Constants.FILE_PATH + modalContentObject.featureImage}
            classNamePassed={"Portfolio-Featured-Grid-Item"}>
            <img className={"Portfolio-Expandable-Image"}
                 src={Constants.FILE_PATH + modalContentObject.featureImage}/></ExpandableImage></Col>
            <Col xs={10} sm={8} lg={8}>
                <article
                    className='Portfolio-Item-Text'><Description text={modalContentObject.description}/>
                </article>
            </Col>
        </Row>
        <Row>
            <Col>
                <article className='Portfolio-Item-Text Portfolio-Item-Text-ExtraDescription'><Description
                    text={modalContentObject.extraDescription}/>
                </article>
            </Col>
        </Row>

<CarouselGallery mediaArray={modalContentObject.media}/>
        {/*<Row className='Portfolio-Gallery-Row g-2 row-cols-1 row-cols-lg-2'>*/}
        {/*    {getModalGalleryMedia(modalContentObject)}*/}
        {/*</Row>*/}


    </Container>

}


function getModalGalleryMedia(modalContentObject) {
    return modalContentObject.media.map((media, index) => {
        if (media.type === 'img') {

            const url = Constants.FILE_PATH + Utils.removeWhiteSpaces(media.path)
            return (
                <Col key={media.id}>
                    <div className='Portfolio-Image-Gallery-Item' style={{backgroundImage: "url(" + url + ")"}}
                         key={media.id}/>
                </Col>)
        }
        if (media.type === 'video') {
            return (
                <video className={"col Portfolio-Video-Gallery-Item"} src={Constants.FILE_PATH + media.path}
                       controls={true}
                       width={'80%'}
                       key={media.id}/>

            )
        }
        if (media.type === 'yt') {
            return (
                <iframe className={"col Portfolio-Video-Gallery-Item"}
                        src={"https://www.youtube.com/embed/" + media.code}
                        key={media.id}
                        title={media.path} frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        width={'80%'}
                        allowFullScreen key={media.id}/>

            )
        }
        if (media.type === '3d') {
            return (<ThreeComponent identifier={media.uuid} key={media.id}/>)
        }

        return ('')
    })
}


export default PortfolioModal;
