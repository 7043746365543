export class Utils {
    static createUUID = () => {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    static removeWhiteSpaces(input) {
        return input.trim().replaceAll(" ", "%20").replaceAll("(", "%28").replaceAll(")", "%29")
    }

    static transferLink(input) {
        if(!input) return;
        const passedString = input?.toString();
        if (passedString.includes("https")) {
            return passedString.split(" ").find(subString => subString.includes("https"))
        }
    }

}