import React, {useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import AdminPanelPortfolioItem from "./AdminPanelPortfolioItem";
import {Constants} from "./Constant";
import {useHistory} from 'react-router-dom'

function AdminPanelPortfolio({handleCallback}) {

    const [portfolioData, setPortfolioData] = useState([])
    const history = useHistory()
    useEffect(() => {
        getCurrentPortfolio()
    }, [])
    const onHandleClick = () => {
        console.log("Callback Received");
        getCurrentPortfolio()
    };

    function getCurrentPortfolio() {
        fetch(Constants.API_BASE_ADDRESS + 'portfolio/items')
            .then(response => response.json())
            .then(data => setPortfolioData(data));
    }

    function logOut() {
        localStorage.clear()
        handleCallback()
    }

    function addPortfolioItem() {
        history.push('/portfolio/add/')
    }

    return (
        <div>
            <div className='Admin-Portfolio-Items-Wrapper'>
                {portfolioData.sort((a, b) => a.rating > b.rating ? -1 : a.rating < b.rating ? 1 : 0).map((item, index) => {
                    return <AdminPanelPortfolioItem key={index} callback={onHandleClick} data={item}/>
                })}
            </div>
            <div className='Admin-Action-Buttons-Right'><Button onClick={addPortfolioItem}>Add New</Button></div>

        </div>
    );


}


export default AdminPanelPortfolio;