import React, {Children, useEffect, useRef, useState} from 'react';
import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {Constants} from "./Constant";

function ThreeComponent({identifier, optionalId, extraClass, elementSize}) {

    const initState = {
        mode: "TEXTURED-",
        container: {},
        controls: {},
        camera: {},
        lights: [],
        scene: {},
        renderer: {},
        isAnimating: false,
        openControls: false,
        meshes: [],
        lightPosition: 1,
        uuid: "",
        geometryBoundingBox: {},
        environmentMap: {},
        lightIntensivity: 100
    }

    const state = useRef(initState)
    const [showControls, setShowControls] = useState(false)
    const [isAnimatingActive, setAnimationActive] = useState(false)

    const [uuid, setUUID] = useState("")

    useEffect(() => {
        setUUID(identifier)
        init(identifier)
        setShowControls(state.current.openControls)
        setAnimationActive(state.current.isAnimating)
    }, [identifier])

    function resetScenePosition(scene) {
        scene.position.x = 0;
        scene.position.y = 0;
        scene.position.z = 0;
    }

    function loadObject(scene, mesh, material) {
        let mat = new THREE.MeshPhongMaterial({color: 0xffffff, side: THREE.DoubleSide})
        var geometry = new THREE.Mesh(mesh.geometry, mesh.material)
        scene.add(geometry)
    }

    function loadMeshesToScene(scene, object) {
        if (object.type === "Group") {
            object.children.forEach(child => loadMeshesToScene(scene, child))
        }
        if (object.type === "Mesh") {
            loadObject(scene, object)
        }
    }

    function determineSize(passedSize) {
        console.log("my size is", passedSize)
        if (passedSize?.width > passedSize?.height) {
            return {
                customWidth: (passedSize.width*0.7),
                customHeight: (passedSize.width*0.7)
            }
        } else {
            return {
                customWidth: passedSize?.width || 600,
                customHeight: passedSize?.height || 400
            }
        }
    }

    function init(uuid) {
        state.current = initState

        console.log("got uuid", uuid)
        state.current.uuid = uuid
        control()

        let camera = new THREE.PerspectiveCamera(45, 1 / 1, 0.01, 100);


        let directionalLight = new THREE.DirectionalLight(0xffffff, 0.5)


        state.current.camera = camera
        const scene = new THREE.Scene();
        state.current.scene = scene;
camera.position.set(0,0,0)
        camera.rotation.set(90,0,90)
        //LOading GLTF
        new GLTFLoader().setPath(Constants.MODEL_READ_ADDRESS + state.current.uuid + '/').load('model.gltf', function (gltf) {
            console.log("Object loaded")

            resetScenePosition(gltf.scene)

            gltf.scene.children.forEach(obj => loadMeshesToScene(scene, obj))


            if (gltf.cameras.length > 0) {

                camera = gltf.cameras[0]
            }

            directionalLight.position.set(-2, -1, -2.5)
            scene.add(directionalLight)

            let directionalLight2 = new THREE.DirectionalLight(0xffffff, 1.5)
            directionalLight2.position.set(2, 1, 2.5)
            scene.add(directionalLight2)


            let ambientColor = 0xffffff
            let backgroundColor = 0x454545

            const ambientLight = new THREE.AmbientLight(ambientColor)
            scene.add(ambientLight)

            let sphereSkyBox = new THREE.SphereGeometry(42, 42, 42)
            let sphereMesh = new THREE.Mesh(sphereSkyBox)
            sphereMesh.material = new THREE.MeshBasicMaterial({color: backgroundColor, side: THREE.BackSide})
            scene.add(sphereMesh)


            render();


        }, (progress) => {
            console.log("Progress: ", progress)
        }, (error) => {
            console.log("Error while loading: ", error)
            console.log(error)
        });


        // set Renderer to DOM element
        const container = document.getElementById("container" + optionalId + state.current.uuid)


        const renderer = new THREE.WebGLRenderer({antialias: true});

        const sizePassed = determineSize(elementSize)
        if (elementSize) {
            renderer.setSize(sizePassed.customWidth, sizePassed.customHeight);

        } else {

            renderer.setSize(container.clientWidth, container.clientHeight);
        }
        state.current.renderer = renderer;
        container.innerHTML = "";
        container.appendChild(renderer.domElement);


        const controls = new OrbitControls(camera, renderer.domElement);
        controls.addEventListener('change', render); // use if there is no animation loop
        controls.minDistance = 1;
        controls.maxDistance = 10;
        controls.target.set(0.005, 0, -0.005);
        controls.update();


    }


    function control() {
        document.getElementById('controlButton' + optionalId + state.current.uuid).addEventListener('click', (e) => {
            state.current.openControls = !state.current.openControls;
            setShowControls(state.current.openControls)
        })
    }


    var render = function () {
        const renderer = state.current.renderer;
        const scene = state.current.scene;
        const camera = state.current.camera;
        renderer.render(scene, camera);

    }
//remove controls if its thumbnail only
    const thumbControlVisibility = extraClass && extraClass.includes("thumb") ? "no-visible" : ''

    return (<div className='Three-Model-Viewer'>
        <div className={`Three-Object ${extraClass || ""}`} id={"container" + optionalId + identifier}></div>

        <span id={'controlButton' + optionalId + identifier}
              className={`Three-Controls-Icon ${thumbControlVisibility}`}><span
            className={`${showControls ? "Three-Controls-Icon-Up" : "Three-Controls-Icon-Down"}`}/></span>

        <div
            className={`Three-Controls ${showControls ? "Three-Controls-Visible" : "Three-Controls-Hidden"} ${thumbControlVisibility}`}
            id={'controlSettings' + optionalId + identifier}>
            <span className='Three-Control-Item-Label'><img src='/img/light-100.png'/></span>
            <input className='Three-Control-Slider' type="range" id={"light" + optionalId + identifier} name="light"
                   min="0"
                   max="250"
                   orient="vertical"/>

        </div>


    </div>);
}


export default ThreeComponent;