import React from 'react';
import {Utils} from "./Utils";


function Description({text}) {
    if(!text){
        return null;
    }
    const linkInText = Utils.transferLink(text)
    const textBeforeLink = (text) => {
        return text.toString().split(linkInText)[0]
    }
    const textAfterLink = (text) => {
        return text.toString().split(linkInText)[1]
    }
    const linkReplacement = (link) => {
        return <a className="description-link"
                  target="_blank"
                  rel="opener"
                  href={link}>here</a>
    }
    return (linkInText ?
        <>{textBeforeLink(text)}
            {linkReplacement(linkInText)}
            {textAfterLink(text)}</>
        : text)
}


export default Description
