import React, {useState} from 'react';
import {Button, Image} from "react-bootstrap";
import {Constants} from "./Constant";
import {useHistory} from 'react-router-dom'
import SpinnerCustom from "./SpinnerCustom";
import ModalAlertConfirmation from "./ModalAlertConfirmation";
import {Utils} from "./Utils";

function AdminPanelPortfolioItem(props) {
    const [showAlert, setShowAlert] = useState(false)
    const [isDeleting, setDeleting] = useState(false)
    const history = useHistory()

    function changeAlertVisibility() {
        setShowAlert(!showAlert)
    }

    function deleteItem() {

        fetch(Constants.API_BASE_ADDRESS + 'delete/item', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('userToken'),
            },
            body: JSON.stringify(props.data),
        })
            .then(response => response.json())
            .then(data => {
                console.log("Will call callback")
                setDeleting(false)
                props.callback()
            })
            .catch((error) => {
                setDeleting(false)
            });
    }

    function editItem() {
        history.push('/portfolio/add/' + props.data.id)
    }



    return (
        <div>
            <div className='Admin-Portfolio-Basic'
                 style={{backgroundImage: "url(" + Constants.FILE_PATH + Utils.removeWhiteSpaces(props.data.featureImage) + ")"}}>

                <p className='Admin-Portfolio-Basic-Title'>{props.data.title}</p>
                {isDeleting ?
                    <div className='Admin-Portfolio-Basic-Spinner-Wrapper'><SpinnerCustom message='deleting'/></div> :
                    <div className='Admin-Portfolio-Basic-Buttons-Wrapper'>
                        <Button className='Admin-Portfolio-Basic-Button' title='Edit' onClick={(e) => editItem()}><img
                            className='Button-Icon' src={Constants.IMG_EDIT}/></Button>
                        <Button className='Admin-Portfolio-Basic-Button' title='Delete'
                                onClick={(e) => changeAlertVisibility()}><img
                            className='Button-Icon' src={Constants.IMG_DELETE}/> </Button>
                    </div>}

            </div>
            <ModalAlertConfirmation show={showAlert} setShow={changeAlertVisibility}
                                    message={"Delete " + props.data.title + '?'}
                                    action={deleteItem} id={props.data.id}/>
        </div>

    );
};


export default AdminPanelPortfolioItem;