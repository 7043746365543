import React from 'react';
import {Container} from "react-bootstrap";


function Modal({show, setShow, content}) {

    function hide() {
        setShow(false)
    }

    const shown = show ? 'Modal-Visible' : 'Modal-Hidden'
    return (<div onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        hide();
        return false;
    }
    } id="mainModal" className={'Modal Modal-Overflow ' + shown}>
        <Container className="Modal-Header">
            <span  className="Modal-Navigation-Button" onClick={hide}>&#215;</span> </Container>
        <div className="Modal-Content" onClick={(event) => event.stopPropagation()}>
            {show ? content : ''}
        </div>
    </div>)
}


export default Modal
