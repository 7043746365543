import React, {useState} from 'react';
import {Button, Container, Form} from 'react-bootstrap';
import ThreeComponent from "./ThreeComponent";
import {Constants} from "./Constant";
import ModalAlertConfirmation from "./ModalAlertConfirmation";

function AdminPanelPortfolioMedia(props) {
    const [showAlert, setShowAlert] = useState(false)
    const [showFields, setShowExtraFields] = useState(false)
    const [imagePreview, setImagePreview] = useState(null);
    const [currentRating, setNewRating] = useState(props.mediaItem.rating || 50)
    const [currentDescription, setNewDescription] = useState(props.mediaItem.description || "")

    function changeAlertVisibility() {
        setShowAlert(!showAlert)
    }

    function onMediaDelete() {
        switch (props.mediaItem.type) {
            case "3d":
                props.onDelete(props.mediaItem.type, props.mediaItem.uuid)
                break;
            case "yt":
                props.onDelete(props.mediaItem.type, props.mediaItem.code)
                break;
            case "video":
                props.onDelete(props.mediaItem.type, props.mediaItem.id)
                break;
            case "img":
                props.onDelete(props.mediaItem.type, props.mediaItem.id)
                break;
            default:
                break;
        }

    }

    function onMediaEdit() {
        switch (props.mediaItem.type) {
            case "3d":
                props.onEditSaved({
                    ...props.mediaItem,
                    rating: currentRating,
                    description: currentDescription
                }, props.mediaItem.id)
                break;
            case "yt":
                props.onEditSaved({
                    ...props.mediaItem,
                    rating: currentRating,
                    description: currentDescription
                }, props.mediaItem.id)
                break;
            case "video":
                props.onEditSaved({
                    ...props.mediaItem,
                    rating: currentRating,
                    description: currentDescription
                }, props.mediaItem.id)
                break;
            case "img":
                props.onEditSaved({
                    ...props.mediaItem,
                    rating: currentRating,
                    description: currentDescription
                }, props.mediaItem.id)
                break;
            default:
                break;
        }

    }

    function get3Dtemplate() {
        return (<Container className='Admin-Three-Component-Wrapper'>
            <ThreeComponent className='Admin-Three-Component' identifier={props.mediaItem.uuid}/>
        </Container>)

    }

    function getYoutubeTemplate(ytCode) {
        var ytLink = "https://www.youtube.com/embed/" + ytCode;
        return (<Container>
            <iframe className='Admin-Youtube-Video' src={ytLink} frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>

        </Container>)
    }

    function getVideoTemplate() {
        return (<Container>
            <video className={"Portfolio-Video-Gallery"}
                   src={props.mediaItem.path ? Constants.FILE_PATH + props.mediaItem.path : props.file ? URL.createObjectURL(props.file.file) : Constants.FILE_PATH + "../media/videoerror.mp4"}
                   controls={true} width={'80%'}
            />

        </Container>)
    }

    function getMediaPreview() {
        return new Promise((res, rej) => {
            if (props.file && props.file.file) {
                var fileReader = new FileReader();
                fileReader.onload = (event) => {
                    res(event.target.result);
                }
                fileReader.readAsDataURL(props.file.file);
            } else {
                rej();
            }
        });
    }

    function getImageTemplate() {

        return (
            <Container>
                <img className="Portfolio-Image-Gallery"
                     src={props.mediaItem.path ? Constants.FILE_PATH + props.mediaItem.path : imagePreview}/>

            </Container>)
    }

    function displayCurrentMedia() {
        switch (props.mediaItem.type) {
            case "3d":
                return get3Dtemplate();
            case "video":
                return getVideoTemplate();
            case "yt":
                return getYoutubeTemplate(props.mediaItem.code);
            case 'img':
                getMediaPreview().then(result => setImagePreview(result))
                    .catch(error => {
                    })
                return getImageTemplate();
            default:
                return "none"

        }
    }

    function getTypeName() {
        switch (props.mediaItem.type) {
            case "3d":
                return '3D';
            case "video":
                return "Video"
            case "yt":
                return 'YouTube video';
            case "img":
                return 'Image';
            default:
                return "none"

        }
    }

    const disabledSaving = currentDescription === props.mediaItem.description && currentRating === props.mediaItem.rating
    return (
        <div className='Admin-Media'>
            <div className='Admin-Media-Header'>
                <div>
                    <p className='Admin-Media-Title'>{props.file && props.file.file ? 'Temporary' : 'Currently'} added {getTypeName()}</p>
                </div>
                <div>
                    <Button onClick={changeAlertVisibility} className='Admin-Three-Delete-Button' title='Delete'>
                        <img className='Button-Icon' src={Constants.IMG_DELETE}/></Button>
                </div>
            </div>

            {displayCurrentMedia()}
            <Form.Check
                type="switch"
                id="fields-visibility-switch"
                className={"text-start"}
                label="Show Description and Rating fields"
                onClick={() => setShowExtraFields(!showFields)}
            />
            {showFields ? <>
                <Form.Group controlId="description" className={"text-start"}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows={2} value={currentDescription}
                                  onChange={(e) => setNewDescription(e.target.value)}
                                  placeholder="Description"/>
                </Form.Group>
                <Form.Group controlId="rating" className={"text-start"}>
                    <Form.Label>Rating (1-100)</Form.Label>
                    <Form.Control type="number" rows={1} value={currentRating}
                                  onChange={(e) => setNewRating(e.target.value)}
                                  placeholder="Rating 1-100"/>
                    <Form.Text className="text-muted">Higher Ranking will display first</Form.Text>

                </Form.Group>
                <Button onClick={onMediaEdit}
                        variant="primary"
                        className="Admin-Submit-Button"
                        disabled={disabledSaving}
                        title={'Save changes'}>
                    Save changes
                </Button>
                <p className={"text-muted"} style={{visibility: disabledSaving ? "hidden" : "visible"}}>After Saving,
                    Remember to Send changes on bottom of this page!</p>
            </> : null}

            <ModalAlertConfirmation show={showAlert} setShow={changeAlertVisibility}
                                    message={"Delete " + getTypeName() + '?'}
                                    action={onMediaDelete} id={props.mediaItem.id}/>
        </div>

    );


}


export default AdminPanelPortfolioMedia;