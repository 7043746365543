import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {useHistory, useParams} from "react-router-dom";
import {Constants} from "./Constant";
import AdminPanelPortfolioMedia from './AdminPanelPortfolioMedia';
import AdminPanelPortfolioMediaAdd from './AdminPanelPortfolioMediaAdd';
import SpinnerCustom from './SpinnerCustom';

function AdminPanelPortfolioAdd({mode}) {
    const [isUpdating, setUpdating] = useState(false)
    const history = useHistory()
    const [item, setItem] = useState({
        description: "",
        title: "",
        rating: 50,
        featureImage: null,
        media: []
    })
    const [featureImagePreview, setFeatureImagePreview] = useState("")
    const featureImageFile = useRef(null);
    const itemId = useParams();
    const [mediaFileObjects, setMediaFileObjects] = useState([])

    useEffect(() => {
        console.log('fetching')
        if (mode === "Edit")
            fetch(Constants.API_BASE_ADDRESS + 'portfolio/item/' + itemId.itemId)
                .then(response => response.json())
                .then(data => setContent(data));
    }, [])
    const deleteCallback = (type, id) => {
        switch (type) {
            case "3d":
                var mediaItemIndex = item.media.findIndex(mediaItem => mediaItem.uuid === id);
                item.media.splice(mediaItemIndex, 1)
                refreshItem()
                break;
            case "yt":
                var mediaItemIndex = item.media.findIndex(mediaItem => mediaItem.code === id);
                item.media.splice(mediaItemIndex, 1)
                refreshItem()
                break;
            case "video":
                var mediaItemIndex = item.media.findIndex(mediaItem => mediaItem.id === id);
                item.media.splice(mediaItemIndex, 1)
                refreshItem()
                break;
            case "img":
                var mediaItemIndex = item.media.findIndex(mediaItem => mediaItem.id === id);
                item.media.splice(mediaItemIndex, 1)
                refreshItem()
                break;

        }
    }
    const updateMediaCallback = (object, id) => {
                var mediaItem = item.media.find(mediaItem => mediaItem.id === id);
                if(mediaItem){
                    const mediaCopy=Object.assign({},mediaItem)
                    mediaCopy.rating=object.rating
                    mediaCopy.description=object.description
                    item.media[item.media.findIndex(mediaItem => mediaItem.id === id)]=mediaCopy
                }
                refreshItem()
    }

    const onMediaAdded = (addMedia) => {
        item.media.push(addMedia.mediaItem)
        var file = addMedia.file;
        let medias = mediaFileObjects;
        medias.push({id: addMedia.mediaItem.id, file: file});
        setMediaFileObjects(medias);
        refreshItem()
    }

    function createPortfolioItem() {
        setUpdating(true)
        var formData = new FormData();
        formData.append('item', JSON.stringify(item))
        if (featureImageFile.current)
            formData.append('featureImage', featureImageFile.current)
        if (mediaFileObjects.length > 0) {
            mediaFileObjects.forEach(media => {
                formData.append(media.id, media.file);
            })
        }

        switch (mode) {
            case "Edit":
                fetch(Constants.API_BASE_ADDRESS + 'update/item', {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Token': localStorage.getItem('userToken'),
                    },
                    body: formData
                }).then((response) => {
                    console.log(response);
                    setUpdating(false)
                    history.push('/admin')
                }).catch(e => {
                    console.log(e);
                    setUpdating(false)
                });
                break;
            case "Add":
                console.log("In add")
                fetch(Constants.API_BASE_ADDRESS + 'create/item', {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Token': localStorage.getItem('userToken'),
                    },
                    body: formData
                }).then((response) => {
                    console.log(response);
                    setUpdating(false)
                    history.push('/admin')
                }).catch(e => {
                    console.log(e);
                    setUpdating(false)
                });
                break;
            default:
                return;
        }

    }

    function setContent(data) {
        setItem(data)
        setFeatureImagePreview(Constants.FILE_PATH + data.featureImage)
    }

    function setValueOnItem(field, value) {
        item[field] = value;
        refreshItem()
    }

    function refreshItem() {
        let updatedItem = JSON.parse(JSON.stringify(item))
        console.log('updated item', updatedItem)
        setItem(updatedItem)
    }

    function cancel() {
        history.push('/admin')
    }

    function addMediaItem() {

    }

    function getMediaFile(mediaId) {
        return mediaFileObjects.find((media) => media.id === mediaId)
    }


    return (
        <div className='Admin-Portfolio-Add'>
            <Container className="Admin-Form">
                {isUpdating ? <div className='Admin-Update-Message'><SpinnerCustom message='Updating your Portfolio'/>
                        <h1>Please wait</h1>
                        <h5>This may take a while...</h5>
                        <p>Do not close this page</p></div> :
                    <div>
                        <Row>
                            <Col className="Admin-Form-Header">
                                <h3>{mode === 'Edit' ? 'Edit portfolio item' : 'Add new content'}</h3></Col>
                            <Col className="Admin-Form-Header Admin-Action-Buttons-Right"><Button
                                className='Admin-Portfolio-Basic-Button' title='Exit'
                                onClick={(e) => cancel()}><img
                                className='Button-Icon' src={Constants.IMG_CLOSE}/></Button></Col>
                        </Row>


                        <Form>
                            <Form.Group controlId="title">
                                <Form.Label>Title</Form.Label>
                                <Form.Control value={item.title}
                                              onChange={(e) => setValueOnItem('title', e.target.value)}
                                              placeholder="Title"/>
                            </Form.Group>
                            <Form.Group controlId="description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={3} value={item.description}
                                              onChange={(e) => setValueOnItem('description', e.target.value)}
                                              placeholder="Description"/>
                                <Form.Text className="text-muted">
                                    Please try to include at least 200 characters. Current length
                                    is {item.description.length}.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="extraDescription">
                                <Form.Label>Extra description</Form.Label>
                                <Form.Control as="textarea" rows={2} value={item.extraDescription}
                                              onChange={(e) => setValueOnItem('extraDescription', e.target.value)}
                                              placeholder="Extra description"/>
                                <Form.Text className="text-muted">
                                    Your participation etc.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="rating">
                                <Form.Label>Rating (1-100)</Form.Label>
                                <Form.Control type="number" rows={1} value={item.rating ? item.rating : 50}
                                              onChange={(e) => setValueOnItem('rating', e.target.value)}
                                              placeholder="Rating 1-100"/>
                                <Form.Text className="text-muted">Higher Ranking will display first</Form.Text>

                            </Form.Group>
                            <div className="Admin-Feature-Image-Wrapper">
                                <img className="Admin-Portfolio-Feature-Image" src={featureImagePreview}/>
                                <Form.Control type="file"
                                              id="featureNewPortfolio"
                                              name="featureImage"
                                              onChange={(e) => {
                                                  var fileReader = new FileReader();
                                                  fileReader.onload = (event) => {
                                                      setFeatureImagePreview(event.target.result)

                                                  }
                                                  featureImageFile.current = e.target.files[0];
                                                  fileReader.readAsDataURL(e.target.files[0]);

                                              }}
                                              label="Feature Image of Portfolio Item"
                                              accept="image/png, image/jpeg"
                                              custom={"true"}
                                />
                            </div>
                            <div>
                                <h1 className='Admin-Header-Media'>Media</h1>
                            </div>
                            <div>
                                {
                                    item.media.map((mediaItem, index) => <AdminPanelPortfolioMedia key={index}
                                                                                                   mediaItem={mediaItem}
                                                                                                   file={getMediaFile(mediaItem.id)}
                                                                                                   onDelete={deleteCallback}
                                                                                                   onEditSaved={updateMediaCallback}
                                    />)
                                }
                            </div>

                            <AdminPanelPortfolioMediaAdd onAdd={onMediaAdded}/>
                            <div className='Admin-Action-Buttons-Right'>
                                <Button onClick={createPortfolioItem}
                                        variant="primary"
                                        className="Admin-Submit-Button"
                                        title={mode === 'Edit' ? 'Send changes' : 'Add to portfolio'}>
                                    {mode === 'Edit' ? 'Send changes' : 'Add to portfolio'}
                                </Button>
                            </div>
                        </Form>

                    </div>
                }
            </Container>
        </div>

    );


}


export default AdminPanelPortfolioAdd;