import React, {useEffect, useState} from 'react';
import Plx from "react-plx"
import PortfolioModal from "./PortfolioModal";
import {Constants} from "./Constant";

const rowsDisplayed = 4;


function Portfolio() {
    const [modalContent, setModalContent] = useState({
        featureImage: "item",
        title: "title1",
        model: "model",
        path: "./img/screenshot1.jpg",
        id: '23',
        media: []
    })
    const [modalVisible, setModalVisible] = useState(false)
    const [portfolioData, setPortfolioData] = useState([])
    useEffect(() => {
        getModels()
    }, []);

    function generatePlexExplosion() {
        const boxes = []
        const photosPerRow = getPhotosPerRow()
        for (let i = 0; i < rowsDisplayed; i++) {
            boxes.push([]);
            for (let j = 0; j < photosPerRow; j++) {
                const top = i < rowsDisplayed / 2;
                const yFactor = top ? -1 : 1;
                const left = j < photosPerRow / 2;
                const xFactor = left ? -1 : 1;
                //     const inside = (i === 1 || i === 2) && (j === 1 || j === 2); // I was lazy to write generic formula
                const inside = i > 0 && i < rowsDisplayed - 1 && j > 0 && j < photosPerRow.length - 1
                const scaleFactor = inside ? 0.5 : 1;
                const start = inside ? 40 : 100;
                const offset = inside ? 40 : 100;
                const rotationFactor = Math.random() > 0.5 ? 180 : -180;


                boxes[i].push({
                    data: [
                        {
                            start: 'self',
                            startOffset: '0vh',
                            duration: '5%',
                            name: 'first',
                            properties: [
                                {
                                    startValue: 0,
                                    endValue: 1,
                                    property: 'opacity',
                                },
                                {
                                    startValue: Math.random() * rotationFactor,
                                    endValue: 0,
                                    property: 'rotate',
                                },
                                {
                                    startValue: Math.random() * scaleFactor,
                                    endValue: 1,
                                    property: 'scale',
                                },
                                {
                                    startValue: (start + Math.random() * offset) * xFactor,
                                    endValue: 0,
                                    property: 'translateX',
                                    unit: '%',
                                },
                                {
                                    startValue: (start + Math.random() * offset) * yFactor,
                                    endValue: 0,
                                    property: 'translateY',
                                    unit: '%',
                                },
                            ],
                        },
                    ]
                });
            }
        }
        return boxes;
    }

    function getPhotosPerRow() {
        return 4
        const minimum768px = window.matchMedia("(min-width: 768px)");
        const minimum1012px = window.matchMedia("(min-width: 1012px)");
        return minimum1012px ? 4 : minimum768px ? 3 : 2
    }


    function generateImages(imageArray, boxes) {
        const imagePerRow = getPhotosPerRow()
        const images = []
        for (var i = 0; i < rowsDisplayed; i++) {
            for (var j = 0; j < imagePerRow; j++) {
                images.push(getImage(boxes[i][j], imageArray[(i * rowsDisplayed) + (j)], i + "" + j))
            }
        }
        return images
    }

    function getModels() {
        fetch(Constants.API_BASE_ADDRESS + 'portfolio/items')
            .then(response => response.json())
            .then(data => setPortfolioData(sortByRating(data)));
    }

    function sortByRating(data) {
        return data.sort((a, b) => a.rating > b.rating ? -1 : a.rating < b.rating ? 1 : 0)
    }

    function getImage(box, image, key) {

        return image ? (<Plx
            key={key}
            className='Portfolio-Feature-Item'
            parallaxData={box.data}>
            <img onClick={() => {
                setModalContent(image)
                setModalVisible(true)
            }} src={Constants.FILE_PATH + image.featureImage} alt={image.title}/>
            <div className='Portfolio-Item-Overlay Portfolio-Item-Overlay-Fade'><h2
                className='Portfolio-Item-Overlay-Title'>{image.title}</h2></div>

        </Plx>) : null
    }

    return (
        <>
            <PortfolioModal modalContent={modalContent} showModal={modalVisible} onChange={(value) => {
                setModalVisible(value)
            }}/>

            <section id="portfolio" className="Section-Portfolio">
                <h1 className="Section-Header">Portfolio</h1>
                {generateImages(portfolioData, generatePlexExplosion())}

            </section>
        </>
    );


}


export default Portfolio;
