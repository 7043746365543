import React, {useEffect, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import {Constants} from "./Constant";

function Contact() {
    const [state, setState] = useState({
        name: "",
        email: "",
        message: "",
        status: ""
    })



    return (
        <section id="contact" className="Contact-Section">
            <header className="Section-Header">Contact</header>
            <p className='Section-Subheader'>Please, contact me by sending message through following form. I will reply ASAP</p>
            <Form className="Contact-Form" onSubmit={submitMessage} >
                <Form.Group controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Name" value={state.name} onChange={onChange} name="name"/>
                    <Form.Text className="text-muted">

                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" name="email" value={state.email}
                                  onChange={onChange} />
                    <Form.Text className="text-muted">

                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="ControlTextarea1">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows="4" name="message" placeholder="Message"
                                  value={state.message} onChange={onChange}/>
                </Form.Group>

                <Button variant="primary" disabled={state.status==="inprogress"} type="submit">
                    Send
                </Button>

                <Form.Text className="text-muted">{state.status}</Form.Text>

            </Form>
        </section>
    );

    function onChange(event) {
        const target = event.target;
        const newState = JSON.parse(JSON.stringify(state))
        switch (target.name) {
            case 'name':
                newState.name = target.value;
                break;
            case 'email':
                newState.email = target.value;
                break;
            case 'message':
                newState.message = target.value
                break;
        }
        setState(newState)
    }

    function setSendingState(status) {
        const newState = JSON.parse(JSON.stringify(state))
        newState.status = status
        setState(newState)
    }

    function submitMessage(event) {
        event.preventDefault();

        setSendingState("inprogress")

        const payload = {
            name: state.name,
            email:state.email,
            message:state.message
        }
        const requestOptions = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload)
        }

        fetch(Constants.API_BASE_ADDRESS+'contact/send', requestOptions)
            .then((response) => {
                setSendingState("Message sent successfully")
            },(error)=>{
                setSendingState("Message couldn't be send this time ",error)
            })
    }


}


export default Contact;
