import React from 'react';


function Resume() {


    return (
        <section id="resume" className="Resume-Section">
            <header className="Section-Header">Resume</header>
        </section>
    );


}


export default Resume;