import React from 'react';
import {Button, Container} from "react-bootstrap";
import {useHistory} from "react-router-dom";

function Footer() {
    const history = useHistory()


    return (

            <div className='Footer'>
                <p className='Footer-Text'><a className='Footer-Email-Link' href="mailto:luna.szymanska.3d@gmail.com?subject=Hello from lunaszymanska.com" target="_blank" rel="noopener noreferrer">Luna Szymanska</a> | {new Date().getFullYear()}</p>
                <Button onClick={(e) => history.push('/admin')} className='Footer-Button'
                        variant="primary">Login</Button>
            </div>

    );
}

export default Footer;
