import React from 'react';
import {Button, Modal} from "react-bootstrap";

function ModalAlertConfirmation({show, setShow, message, action, id}) {

    function handleYes() {
        action()
        setShow()
    }

    function handleNo() {
        setShow()
    }
    return (
        <>
            <Modal title='Modal-Alert-Small' key={'ModalAlert' + id} id={'MediaModalAlert' + id} show={show} >
                <Modal.Header>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => handleNo()}>
                        No
                    </Button>
                    <Button variant="primary" onClick={(e) => handleYes()}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default ModalAlertConfirmation;
