import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Dropdown, DropdownButton, Form, Row} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import SpinnerCustom from "./SpinnerCustom";
import {Constants} from "./Constant";

function AdminPanelMainPageCarouselNew({mode}) {
    const [isUpdating, setUpdating] = useState(false)
    const [item, setItem] = useState({
        title: '',
        subtitle: "",
        rating:50
    })
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null)
    const routeParams = useParams();
    const [typeDisplayed, setTypeDisplayed] = useState('Type');

    const history = useHistory()
    useEffect(() => {
        if (mode === "Edit")
            fetch(Constants.API_BASE_ADDRESS + 'portfolio/slide/' + routeParams.itemId)
                .then(response => response.json())
                .then(data => setItem(data));
        console.log('item', item)
    }, [])

    function setValueOnItem(field, value) {
        item[field] = value;
        let updatedItem = JSON.parse(JSON.stringify(item))
        setItem(updatedItem)
    }


    function setTypeSelected(type) {
        setValueOnItem('type', type)
        getTypeName(type)
    }

    function getTypeName(typeString) {
        switch (typeString) {
            case "video":
                setTypeDisplayed("Video")
                break;
            case "img":
                setTypeDisplayed('Image');
                break;
            default:
                setTypeDisplayed("Type")

        }
    }


    function getMediaPreview(file) {
        return new Promise((res, rej) => {
            if (file) {
                var fileReader = new FileReader();
                fileReader.onload = (event) => {
                    res(event.target.result);
                }
                fileReader.readAsDataURL(file);
            } else {
                rej();
            }
        });
    }

    function createCarouselItem() {
        setUpdating(true)
        var formData = new FormData();
        formData.append('item', JSON.stringify(item))
        formData.append('file', file)


        switch (mode) {
            case "Edit":
                fetch(Constants.API_BASE_ADDRESS + 'update/slide', {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Token': localStorage.getItem('userToken'),
                    },
                    body: formData
                }).then((response) => {
                    console.log(response);
                    setUpdating(false)

                    history.push('/admin')
                }).catch(e => {
                    console.log(e);
                    setUpdating(false)
                });
                break;
            case "Add":
                console.log("In add")
                fetch(Constants.API_BASE_ADDRESS + 'create/slide', {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Token': localStorage.getItem('userToken'),
                    },
                    body: formData
                }).then((response) => {
                    console.log(response);
                    setUpdating(false)
                    history.push('/admin')
                }).catch(e => {
                    console.log(e);
                    setUpdating(false)
                });
                break;
            default:
                return;
        }

    }


    function validateForm() {
        if (mode == 'Add') {
            return item.title.length > 0 && item.subtitle.length > 0 && item.type !== 'Type' && file !== null
        } else {
            return item.title.length > 0 && item.subtitle.length > 0

        }

    }

    function cancel() {
        history.push('/admin')
    }

    return (
        <div>
            <section>
                <Container className='Admin-Carousel-New'>
                    {isUpdating ?
                        <div className='Admin-Update-Message'>
                            <SpinnerCustom message='Updating your Carousel slides'/>
                            <h1>Please wait</h1>
                            <h5>This may take a while...</h5>
                            <p>Do not close this page</p>
                        </div> :
                        <div>
                            <Row>
                                <Col className="Admin-Form-Header">
                                    <h3>{mode === 'Edit' ? 'Edit Carousel slide' : 'Add new slide'}</h3></Col>
                                <Col className="Admin-Form-Header Admin-Action-Buttons-Right"><Button
                                    className='Admin-Carousel-Basic-Button' title='Exit'
                                    onClick={(e) => cancel()}><img
                                    className='Button-Icon' src={Constants.IMG_CLOSE}/></Button></Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col>

                                    <Form onSubmit={createCarouselItem}>

                                        <Form.Group controlId="title">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control value={item.title}
                                                          onChange={(e) => setValueOnItem('title', e.target.value)}
                                                          placeholder="Enter title"/>
                                            <Form.Text className="text-muted">
                                                This is first line
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group controlId="subtitle">
                                            <Form.Label>Subtitle</Form.Label>
                                            <Form.Control value={item.subtitle}
                                                          onChange={(e) => setValueOnItem('subtitle', e.target.value)}
                                                          placeholder="Enter subtitle"/>
                                            <Form.Text className="text-muted">
                                                This is second, smaller line
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group controlId="rating">
                                            <Form.Label>Rating (1-100)</Form.Label>
                                            <Form.Control value={item.rating?item.rating:50}
                                                          onChange={(e) => setValueOnItem('rating', e.target.value)}
                                                          placeholder="Enter Rating"/>
                                            <Form.Text className="text-muted">
                                                Slides with higher rating will display first
                                            </Form.Text>
                                        </Form.Group>
                                        <div className='Admin-Carousel-Media-Wrapper'>
                                            {item.type == 'img' ?
                                                <img className="Portfolio-Image-Gallery" src={item.path ? item.path : imagePreview}/> :
                                                <video className='Admin-Carousel-Video'
                                                       src={item.path ? item.path : file ? URL.createObjectURL(file) : ""}/>
                                            }
                                        </div>
                                        {mode == 'Add' ?
                                            <div><DropdownButton id="dropdown-basic-button" title={typeDisplayed}>
                                                <Dropdown.Item
                                                    onSelect={(e) => setTypeSelected('img')}>Image</Dropdown.Item>
                                                <Dropdown.Item
                                                    onSelect={(e) => setTypeSelected('video')}>Video</Dropdown.Item>
                                            </DropdownButton>

                                                <input type={"file"}
                                                    id="custom-file"
                                                    onChange={(e) => {
                                                        setFile(e.target.files[0])
                                                        getMediaPreview(e.target.files[0]).then(preview => setImagePreview(preview))
                                                            .catch(error => console.log(error))
                                                    }}
                                                    label={item.type === 'img' ? 'Select image .png/.jpg' : 'Select video .mp4'}
                                                    accept={item.type === 'img' ? 'image/png, image/jpeg' : 'video/mp4'}
                                                    custom
                                                /></div> : ''}
                                        <Button variant="primary" type="submit" disabled={!validateForm()}>
                                            {mode == 'Add' ? 'Add' : 'Send changes'}
                                        </Button>
                                    </Form>
                                </Col></Row>
                        </div>}
                </Container>
            </section>
        </div>
    );

}


export default AdminPanelMainPageCarouselNew;