import React from 'react';
import './App.css';
import {BrowserRouter as Router, Redirect, Route, Switch,Link as RouterLink} from 'react-router-dom'
import {Link} from "react-scroll";
import RouteModel from "./RouteModel";
import Resume from "./Resume";
import Portfolio from "./Portfolio";
import AboutMe from "./AboutMe";
import CarouselComponent from "./CarouselComponent";
import Plx from 'react-plx';
import Contact from "./Contact";
import AdminPanel from "./AdminPanel";
import AdminPanelPortfolioAdd from "./AdminPanelPortfolioAdd";
import Footer from "./Footer";
import AdminPanelMainPageCarouselNew from "./AdminPanelMainPageCarouselNew";
import {Container} from "react-bootstrap";

const routes = [
    new RouteModel("Home", "home", CarouselComponent),
    new RouteModel("About Me", "aboutme", AboutMe),
    new RouteModel("Portfolio", "portfolio", Portfolio),
   // new RouteModel("Resume", "resume", Resume),
    new RouteModel("Contact", "contact", Contact),
    new RouteModel("", "/", <Redirect to="home"/>)
]


function createRoute(route, index) {
    return (<Route path={route.routePath} key={index}>{route.routeComponent}</Route>)
}

function getRoutes(routes) {
    return routes.map((route, index) =>
        createRoute(route, index)
    )
}


function App() {
    const opacity = [
        {
            start: 0,
            duration: '.Carousel-Section',
            unit: "vh",
            ease: "easeIn",
            properties: [
                {
                    startValue: 0.6,
                    endValue: 1,
                    property: 'opacity'
                },
                {
                    startValue: 'rgba(255,255,255,0)',
                    endValue: 'rgba(255,255,255,0.7)',
                    property: 'borderBottomColor'
                },

            ],
        }
    ];

    return (
        <Router>
            <Container className="App">
                <Switch>
                    <Route path='/admin'>
                        <AdminPanel/>
                    </Route>
                    <Route path='/portfolio/add/:itemId'>
                        <AdminPanelPortfolioAdd mode='Edit'/>
                    </Route>
                    <Route path='/portfolio/add/'>
                        <AdminPanelPortfolioAdd mode='Add'/>
                    </Route>
                    <Route path='/carousel/add/:itemId'>
                        <AdminPanelMainPageCarouselNew mode='Edit'/>
                    </Route>
                    <Route path='/carousel/add/'>
                        <AdminPanelMainPageCarouselNew mode='Add'/>
                    </Route>
                    <Route path="/">
                        <Plx className="App-header" parallaxData={opacity}>
<Container>
                            <header>

                                <h3 className="Header-Title">Luna Szymanska<br/>N-gon chaser</h3>

                                <nav className="App-Links">
                                    <ul className="Navigation-List">
                                        {routes.filter((route) => route.routeName.length > 0)
                                            .map((route, index) => (
                                                <li key={index} className="Navigation-Item">
                                                    <Link smooth offset={-100} activeClass="Navigation-Link-Active"
                                                          to={route.routePath}
                                                          spy={true}>
                                                        {route.routeName}
                                                    </Link></li>
                                            ))}
                                    </ul>
                                </nav>
                            </header></Container>
                        </Plx>
                        <div className="Content">
                            <CarouselComponent/>
                            <AboutMe/>
                            <Portfolio/>
                            {/*<Resume/>*/}
                            <Contact/>
                            <Footer/>
                        </div>
                    </Route>

                </Switch>
            </Container>
        </Router>
    );
}


export default App;
