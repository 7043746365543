import React, {useState} from 'react';
import {Button, Dropdown, DropdownButton, Form} from 'react-bootstrap';
import {Constants} from "./Constant";
import {Utils} from "./Utils";

function AdminPanelPortfolioMediaAdd(props) {
    const [type, setType] = useState('');
    const [file, setFile] = useState(null);
    const [id, setId] = useState('');
    const [description, setDescription] = useState('');
    const [rating, setRating] = useState(50);
    const [typeDisplayed, setTypeDisplayed] = useState('Type');

    function addMediaItem() {
        let media = createMediaItem(type, id, file, description, rating);
        props.onAdd(media);
        setRating(50)
        setDescription("")
        setFile(null)
        setType('')
        setId('')
        setTypeDisplayed('Type')
    }


    function createMediaItem(type, id, file,description,rating) {
        switch (type) {
            case "3d":
                return {mediaItem: {id: Utils.createUUID(), type: "3d", uuid: id}, file: null, rating:rating, description:description}
            case "yt":
                return {mediaItem: {id: Utils.createUUID(), type: "yt", code: id}, file: null,  rating:rating, description:description}
            case 'video':
                return {mediaItem: {id: Utils.createUUID(), type: 'video', path: null}, file: file, rating:rating, description:description}
            case 'img':
                return {mediaItem: {id: Utils.createUUID(), type: 'img', path: null}, file: file,  rating:rating, description:description}
        }
    }

    function redirectToModelUploader() {
        window.open(Constants.MODEL_UPLOADER_ADDRESS)
    }

    function setMediaType(typeString) {
        getTypeName(typeString)
        setType(typeString)
        setRating(rating)
        setDescription(description)
        setFile(null)
        setId('')
        console.log("selected",typeString)
    }

    function getTypeName(typeString) {
        switch (typeString) {
            case "3d":
                setTypeDisplayed('3D');
                break;
            case "video":
                setTypeDisplayed("Video")
                break;
            case "yt":
                setTypeDisplayed('YouTube video');
                break;
            case "img":
                setTypeDisplayed('Image');
                break;
            default:
                setTypeDisplayed("Type")

        }
    }

    function validateForm() {
        switch (type) {
            case "3d":
                return id != ''
                break;
            case "video":
                return file != null
                break;
            case "yt":
                return id != ''
                break;
            case "img":
                return file != null
                break;
            default:
                return false

        }

    }

    return (
        <div className='Admin-Media-Add'>
            <p className='Admin-Media-Add-Title'>Add new media</p>

                <DropdownButton id="dropdown-basic-button" title={typeDisplayed}>
                    <Dropdown.Item onSelect={(e) => setMediaType('3d')} onClick={(e) => setMediaType('3d')}>3D</Dropdown.Item>
                    <Dropdown.Item onSelect={(e) => setMediaType('video')} onClick={(e) => setMediaType('video')}>Video</Dropdown.Item>
                    <Dropdown.Item onSelect={(e) => setMediaType('yt')} onClick={(e) => setMediaType('yt')}>Youtube Video</Dropdown.Item>
                    <Dropdown.Item onSelect={(e) => setMediaType('img')} onClick={(e) => setMediaType('img')}>Image</Dropdown.Item>
                </DropdownButton>
                <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows={3} value={description}
                                  onChange={(e) => setDescription( e.target.value)}
                                  placeholder="Description"/>
                    <Form.Text className="text-muted">
                        Please try to include at least 200 characters. Current length is {description.length}.
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="rating">
                    <Form.Label>Rating (1-100)</Form.Label>
                    <Form.Control type="number" rows={1} value={rating ? rating : 50}
                                  onChange={(e) => setRating(Number(e.target.value))}
                                  placeholder="Rating 1-100"/>
                    <Form.Text className="text-muted">Higher Ranking will display first</Form.Text>

                </Form.Group>
                {type === 'yt' ? <Form.Group controlId="ytLink">
                    <Form.Label>YouTube video id</Form.Label>
                    <Form.Control value={id} onChange={(e) => setId(e.target.value)}
                                  placeholder="Youtube video id"/>
                    <Form.Text className="text-muted">
                        You can find it in video url/link after <b>=</b> sign / example ->
                        www.youtube.com/watch?v=<b>eCbyqm9jcBA</b> <br/> https://youtu.be/<b>em3tTFx8oD0</b>
                    </Form.Text>
                </Form.Group> : ''}
                {type === '3d' ?
                    <div><Button onClick={redirectToModelUploader} target='_blank'>Generate Model UUID</Button>
                        <Form.Group controlId="uuid">
                            <Form.Label>Model UUID</Form.Label>
                            <Form.Control value={id} onChange={(e) => setId(e.target.value)}
                                          placeholder="Model UUID"/>
                        </Form.Group></div> : ''}
                {type === 'video' ?
                    <Form.Control type="file"
                        id="newPortfolioMedia"
                        onChange={(e) => setFile(e.target.files[0])}
                        label="Add Video .mp4"
                        accept="video/mp4"
                    /> : ''}
                {type === 'img' ?
                    <Form.Control type="file"
                        id="newPortfolioMedia"
                        onChange={(e) => setFile(e.target.files[0])}
                        label="Add Image .img/.png"
                        accept="image/png, image/jpeg"
                    /> : ''}

                <Button disabled={!validateForm()} onClick={addMediaItem} variant="primary" title='Add new media'>
                    Add media
                </Button>

        </div>

    );


}


export default AdminPanelPortfolioMediaAdd;