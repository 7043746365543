export class Constants {
    static SERVER_PATH = "https://lunaszymanska.com"
    static API_BASE_ADDRESS = "https://api.lunaszymanska.com/";
    static FILE_PATH = Constants.SERVER_PATH;
    static MODEL_UPLOADER_ADDRESS = Constants.SERVER_PATH + "/three/uploader.html"
    static MODEL_READ_ADDRESS = Constants.SERVER_PATH +"/three/model/"
    static IMG_EDIT = '/img/pencil.png';
    static IMG_DELETE = '/img/trash.png';
    static IMG_PLUS = '/img/add.png'
    static IMG_CLOSE = '/img/close.png'
}