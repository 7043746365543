import React, {useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import md5 from 'md5-hash';
import {Constants} from "./Constant";

function AdminPanelLogin({handleCallback}) {
    const [user, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [invalid, setInvalid] = useState(false);

    function validateForm() {

        return user.length > 0 && password.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();

        const data = {
            username: user,
            password: md5(password)
        }
        fetch(Constants.API_BASE_ADDRESS+'authorize', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                if (data.token) {
                    localStorage.setItem('userToken', data.token)
                }
                handleCallback();
            })
            .catch((error) => {
                console.error('Error:', error);
                setUsername('')
                setPassword('')
                setInvalid(true);
                setTimeout(() => {
                    setInvalid(false)
                }, 2000);
                handleCallback();
            });
    }


    return (
        <div><Button href={'/'}>Oops, lets go back to Homepage</Button>
        <section className='Login-Page'>

            {invalid ? <h2>Looks like something went wrong</h2> : <h2>Hi!</h2>}
            <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} controlId="formEmail">
                    <Form.Label className='Login-Label' column sm="2">
                        Username
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control autoFocus
                                      value={user}
                                      onChange={(e) => setUsername(e.target.value)} placeholder="Username"/>
                    </Col>


                </Form.Group>

                <Form.Group as={Row} controlId="formPassword">
                    <Form.Label className='Login-Label' column sm="2">
                        Password
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control type="password"
                                      value={password}
                                      onChange={(e) => setPassword(e.target.value)} placeholder="Password"/>
                    </Col>
                </Form.Group>
                <Button variant="primary" type="submit" disabled={!validateForm()}>
                    Submit
                </Button>
            </Form>
        </section></div>
    );


}


export default AdminPanelLogin;