import React, {useEffect, useState} from 'react';
import AdminPanelMainPageCarousel from "./AdminPanelMainPageCarousel";
import AdminPanelAboutMe from "./AdminPanelAboutMe";
import AdminPanelPortfolio from "./AdminPanelPortfolio";
import {useHistory} from "react-router-dom";
import {Accordion, Button, Card} from "react-bootstrap";

function AdminPanelContent({handleCallback}) {
    const history = useHistory()

    function logOut() {
        localStorage.clear()
        handleCallback()
    }

    return (
        <div>
            <Button onClick={(e) => history.push('/')} className='Footer-Button'
                    variant="primary">Home page</Button>
            <Button onClick={logOut}>Log out</Button>
            <Accordion defaultActiveKey="2" flush>
                <Card className='Admin-Card'>
                    <Accordion.Item eventKey={"0"} className={"Admin-Accordion"}>
                        <Accordion.Header className='Admin-Card-Header' as={Card.Header}>
                            About Me section
                        </Accordion.Header>
                        <Accordion.Body>
                            <Card.Body className='Admin-Card-Body'>
                                <AdminPanelAboutMe/>
                            </Card.Body>
                        </Accordion.Body>
                    </Accordion.Item>
                </Card>

                <Card className='Admin-Card'>
                    <Accordion.Item eventKey={"1"} className={"Admin-Accordion"}>
                        <Accordion.Header className='Admin-Card-Header' as={Card.Header}>
                            Main Page section (<i>carousel items</i>)
                        </Accordion.Header>
                        <Accordion.Body>
                            <Card.Body className='Admin-Card-Body'><AdminPanelMainPageCarousel/></Card.Body>
                        </Accordion.Body>
                    </Accordion.Item>
                </Card>
                <Card className='Admin-Card'>
                    <Accordion.Item eventKey={"2"} className={"Admin-Accordion"}>
                        <Accordion.Header className='Admin-Card-Header' as={Card.Header}>
                            Portfolio section
                        </Accordion.Header>
                        <Accordion.Body>
                            <Card.Body className='Admin-Card-Body'><AdminPanelPortfolio/></Card.Body>
                        </Accordion.Body>
                    </Accordion.Item>
                </Card>
            </Accordion>
        </div>
    );


}


export default AdminPanelContent;