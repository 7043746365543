import React, {useRef, useState} from "react";
import {Carousel, Col, Container, Row} from "react-bootstrap";
import {Constants} from "./Constant";
import {Utils} from "./Utils";
import ThreeComponent from "./ThreeComponent";
import logo from "./img/icon192.png"
import Description from "./DescriptionComponent";

function CarouselGallery({mediaArray}) {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const generateSlideMedia = (media, itemIndex) => {
        if (media.type === 'img') {

            const url = Constants.FILE_PATH + Utils.removeWhiteSpaces(media.path)
            return (

                <div className='col Portfolio-Image-Gallery-Item Carrousel-Image'
                     style={{width: "100%", backgroundImage: "url(" + url + ")"}}
                     key={media.id}/>
            )
        }
        if (media.type === 'video') {
            return (
                <video className={"col Portfolio-Video-Gallery-Item Carrousel-Image"}
                       src={Constants.FILE_PATH + media.path}
                       controls={true}
                       width={'80%'}
                       key={media.id}/>

            )
        }
        if (media.type === 'yt') {
            return (
                <iframe className={"col Portfolio-Video-Gallery-Item Carrousel-Image"}
                        src={"https://www.youtube.com/embed/" + media.code}
                        title={media.path} frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        width={'80%'}
                        allowFullScreen key={media.id}/>

            )
        }
        if (media.type === '3d') {
            const carouselSlide = document.getElementsByClassName("carousel-inner")
            const elementSizeExpected = {
                width: carouselSlide.item(0).clientWidth,
                height: (carouselSlide.item(0).clientWidth / 3) * 2
            }
            return (<ThreeComponent identifier={media.uuid} optionalId={"-carrousel-"} key={"3D-" + media.id}
                                    extraClass={"Three-Item-Gallery"} elementSize={elementSizeExpected}/>)
        }
    }
    const generateInteractiveMedia = (media, index) => {
        if (media.type === 'img') {

            const url = Constants.FILE_PATH + Utils.removeWhiteSpaces(media.path)
            return (

                <div className='col Portfolio-Image-Gallery-Item Carrousel-Image' onClick={() => handleSelect(index)}
                     style={{maxWidth: "inherit", backgroundImage: "url(" + url + ")"}}
                     key={"thumb-" + media.id}/>
            )
        }
        if (media.type === 'video' || media.type === 'yt') {
            return (
                <div className={"col video-thumb"} key={"thumb-" + media.id} onClick={() => handleSelect(index)}>
                    <i className="video-thumb-icon fs-6">&#9658;</i>
                </div>
            )
        }
        if (media.type === '3d') {

            return (<div className={"col carrousel-item-3d-thumb"} key={"3D-thumb-" + media.id} onClick={() => setIndex(index)}>
                <img src={logo} alt="3D" />

            </div>)
        }
    }
    const generateSlide = (item, index) => {
        return <Carousel.Item key={"carrouselMedia" + item.id}>{generateSlideMedia(item, index)}
            {item.description && item.type==="img"?
                <Carousel.Caption>
                <p className={"Carrousel-Gallery-Image-Description"}><Description text={item.description}/></p>
            </Carousel.Caption> : null}
        </Carousel.Item>
    }
    return (<>
        <Container className={"mb-5"}>
            <Carousel activeIndex={index} interval={null} onSelect={handleSelect} controls={false}>
                {mediaArray.map((item, index) => generateSlide(item, index))}
            </Carousel>
            <Row
                className={" px-2 g-2 mt-2 row-cols-8 align-items-center justify-content-center"}>{mediaArray.map((item, index) => generateInteractiveMedia(item, index))}</Row>
        </Container>
    </>)
}

export default CarouselGallery