import React, {useEffect, useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Plx from 'react-plx';
import {Constants} from "./Constant";


function CarouselComponent() {
    const swipeIn = [
        {
            start: '.About-Me-Image',
            duration: '.About-Me-Section',
            properties: [
                {
                    startValue: 1,
                    endValue: 0.1,
                    property: 'opacity'
                },
                {
                    startValue: 0,
                    endValue: 4,
                    property: 'blur'
                },
            ],
        }
    ];
    const [data, setData] = useState([""])

    useEffect(() => {
        fetch(Constants.API_BASE_ADDRESS + 'portfolio/slides')
            .then((data) => data.json())
            .then((data) => {
                setData(sortByRating(data))
            })
    }, [])


    function sortByRating(data) {
        return data.sort((a, b) => a.rating > b.rating ? -1 : a.rating < b.rating ? 1 : 0)
    }

    return (
        <section id="home" className="Carousel-Section">
            <Plx parallaxData={swipeIn}>

                <h5 className="Main-Welcome-Text">Hi I am Luna<br/>
                    I love Video games and 3D modeling...</h5>
                <Carousel indicators={false} className="Carousel">

                    {data.map((item, index) => {
                        return <Carousel.Item key={index}>
                            {item.type === 'img' ?
                                <img
                                    className="d-block w-100 Carousel-Item"
                                    src={item.path}
                                    alt={item.title}
                                />
                                :
                                <video src={item.path} className="d-block w-100 Carousel-Item" autoPlay loop
                                       muted/>}
                            <Carousel.Caption>
                                <h3>{item.title}</h3>
                                <p>{item.subtitle}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    })}
                </Carousel>
            </Plx>
        </section>
    );
}


export default CarouselComponent;
