import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {Constants} from "./Constant";
import SpinnerCustom from "./SpinnerCustom";
import {useHistory} from 'react-router-dom';
import ModalAlertConfirmation from "./ModalAlertConfirmation";

function AdminPanelMainPageCarouselItem(props) {
    const [showAlert, setShowAlert] = useState(false)
    const [isDeleting, setDeleting] = useState(false)
    const history = useHistory()
    useEffect(() => {
        if (props.item.type == 'video') {
            drawVideoOnCanvas()
        }

    }, [])

    function drawVideoOnCanvas() {
        let canvas = document.getElementById('canvas' + props.item.id);
        let video = document.createElement("video", [])
        video.src = Constants.FILE_PATH + '/' + props.item.path
        video.volume = 0.00001;
        video.autoplay = true;
        video.loop = true;
        // video.setAttribute('width'
        //     ,
        //     "300"
        // )

        drawFrame(video, canvas)
    }

    function drawFrame(video, canvas) {
        // console.log('video width', video.videoWidth)
        // console.log('video height', video.videoHeight)
        let videoProportion = (video.videoWidth / video.videoHeight);
        // console.log(videoProportion)
        // console.log('scaled width and height', 300 * videoProportion, 300)
        let baseHeight = 250;
        let newWidth = baseHeight * videoProportion;

        canvas.getContext("2d").drawImage(video, -100, -50, newWidth, baseHeight)
        setTimeout(() => drawFrame(video, canvas), 1000 / 30)
    }

    function changeAlertVisibility() {
        setShowAlert(!showAlert)
    }

    function handleSubmit(event) {
        event.preventDefault();


    }

    function deleteItem() {

        fetch(Constants.API_BASE_ADDRESS + 'delete/slide', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('userToken'),
            },
            body: JSON.stringify(props.item),
        })
            .then(response => response.json())
            .then(data => {
                console.log("Will call callback")
                setDeleting(false)
                props.callback()
            })
            .catch((error) => {
                setDeleting(false)
            });
    }

    function editItem() {
        history.push('/carousel/add/' + props.item.id)
    }

    return (
        <div>

            <div className='Admin-Slide-Wrapper'
                 style={props.item.type === 'img' ? {backgroundImage: "url(" + Constants.FILE_PATH + props.item.path + ")"} : {}}>
                {props.item.type !== 'img' ?
                    <canvas className='Admin-Slide-Canvas' id={'canvas' + props.item.id}/> : ""
                }

                <div className='Admin-Slide-Title-Wrapper'>
                    <p className='Admin-Slide-Basic-Title'>{props.item.title}</p>
                    <p className='Admin-Slide-Basic-Subtitle'>{props.item.subtitle}</p>
                </div>
                {isDeleting ?
                    <div className='Admin-Portfolio-Basic-Spinner-Wrapper'><SpinnerCustom message='deleting'/>
                    </div> :
                    <div className='Admin-Portfolio-Basic-Buttons-Wrapper'>
                        <Button className='Admin-Portfolio-Basic-Button' title='Edit'
                                onClick={(e) => editItem()}><img
                            className='Button-Icon' src={Constants.IMG_EDIT}/></Button>
                        <Button className='Admin-Portfolio-Basic-Button' title='Delete'
                                onClick={(e) => changeAlertVisibility()}><img
                            className='Button-Icon' src={Constants.IMG_DELETE}/> </Button>
                    </div>}

            </div>
            <ModalAlertConfirmation show={showAlert} setShow={changeAlertVisibility}
                                    message={"Delete " + props.item.title + '?'}
                                    action={deleteItem} id={props.item.id}/>
        </div>
    );


}


export default AdminPanelMainPageCarouselItem;