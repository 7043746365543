import React, {useCallback, useEffect, useState} from 'react';
import {Constants} from "./Constant";
import AdminPanelContent from "./AdminPanelContent";
import AdminPanelLogin from "./AdminPanelLogin";

function AdminPanel() {
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [isValidating, setValidating] = useState(false)
    useEffect(() => {
        checkValidUser()
    }, [])
    const onHandleClick = useCallback(() => {
        checkValidUser()
    }, []);

    function checkValidUser() {
        setValidating(true)
        if (localStorage.getItem('userToken')) {
            const data = {
                token: localStorage.getItem('userToken')
            }
            fetch(Constants.API_BASE_ADDRESS + 'validate', {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            })
                .then(data => {
                    setLoggedIn(true)
                    setValidating(false)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoggedIn(false)
                    setValidating(false)

                });
        } else {
            setValidating(false)
            setLoggedIn(false)
            setValidating(false)
        }
    }

    return (
        <div id="controlPanel">
            {isValidating ? '' : <>
                <header className="Section-Header">Control Panel</header>

                {isLoggedIn ? <AdminPanelContent handleCallback={onHandleClick}/>
                    :
                    <AdminPanelLogin handleCallback={onHandleClick}/>}</>}
        </div>
    );


}


export default AdminPanel;